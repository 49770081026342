import * as React from "react"
import { Link } from "gatsby"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const VpsPage = () => (
  <div>
    <SEO 
        title="VPS Packages" 
        description="The Virtual Private Server plans are loaded with all the characteristics and software tools you may need in order to 
        manage your resource–demanding sites and apps from a single area."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="antialiased max-w-6xl mx-auto mt-24 mb-12 px-8">
        <div className="text-center mx-auto">
            <p className="section-title m-0 p-0"><span className="block text-indigo-600 xl:inline">VPS</span> Packages <span role="img" aria-label="emoji">✨ </span></p>
        </div>
        <div className="relative block md:flex items-center">
            <div className="w-full md:w-1/2 relative z-1 bg-gray-100 rounded shadow-lg overflow-hidden">
                <div className="text-lg font-medium text-indigo-500 uppercase p-6 text-center border-b border-gray-200 tracking-wide">KVM 2 Package</div>
                <div className="block sm:flex md:block lg:flex items-center justify-center">
                <div className="mt-8 sm:m-8 md:m-0 md:mt-8 lg:m-8 text-center">
                    <div className="inline-flex items-center">
                    <span className="text-3xl font-medium"><small className="text-sm align-top">$</small>16.00</span>
                    </div>
                    <span className="block text-sm text-gray-600 mt-2">per month</span>
                </div>
                </div>
                <div className="flex justify-center mt-3">
                <ul>
                    <li className="flex items-center">
                    <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                        <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <span className="text-gray-700 text-lg ml-3">40 GB Disk Space</span>
                    </li>
                    <li className="flex items-center mt-3">
                    <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <span className="text-gray-700 text-lg ml-3">2000 GB Monthly Traffic</span>
                    </li>
                    <li className="flex items-center mt-3">
                    <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <span className="text-gray-700 text-lg ml-3">2048 MB RAM</span>
                    </li>
                    <li className="flex items-center mt-3">
                    <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <span className="text-gray-700 text-lg ml-3">2 CPU Core(s)</span>
                    </li>
                    <li className="flex items-center mt-3">
                    <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <span className="text-gray-700 text-lg ml-3">1 IP Addresses</span>
                    </li>
                    <li className="flex items-center mt-3">
                    <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <span className="text-gray-700 text-lg ml-3">Unlimited Hosted Domains</span>
                    </li>
                    <li className="flex items-center mt-3">
                    <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <span className="text-gray-700 text-lg ml-3">CentOS/Debian/Ubuntu</span>
                    </li>
                </ul>
                </div>
                <Link className="block flex items-center justify-center bg-gray-200 hover:bg-gray-300 p-6 text-md font-semibold text-gray-800 uppercase mt-16" to="/hosting">
                <span>Purchase</span>
                <span className="font-medium text-gray-700 ml-2">➔</span>
                </Link>
            </div>
            <div className="w-full md:w-1/2 relative z-0 px-8 md:px-0 md:py-16">
                <div className="bg-indigo-800 text-white rounded-b md:rounded-b-none md:rounded-r shadow-lg overflow-hidden">
                <div className="text-lg font-medium uppercase p-4 text-center border-b border-indigo-500 tracking-wide">vBox 1 Package</div>
                
                <div className="block sm:flex md:block lg:flex items-center justify-center">
                <div className="mt-8 sm:m-8 md:m-0 md:mt-8 lg:m-8 text-center">
                    <div className="inline-flex items-center">
                    <span className="text-3xl font-medium"><small className="text-sm align-top">$</small>12.00</span>
                    </div>
                    <span className="block text-sm text-indigo-200 mt-2">per month</span>
                </div>
                </div>
                <div className="flex justify-center mt-3">
                    <ul>
                        <li className="flex items-center">
                        <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                            <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <span className="text-indigo-200 text-md ml-3">30 GB Disk Space</span>
                        </li>
                        <li className="flex items-center mt-3">
                        <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                        <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <span className="text-indigo-200 text-md ml-3">1000 GB Monthly Traffic</span>
                        </li>
                        <li className="flex items-center mt-3">
                        <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                        <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <span className="text-indigo-200 text-md ml-3">1024 MB RAM</span>
                        </li>
                        <li className="flex items-center mt-3">
                        <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                        <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <span className="text-indigo-200 text-md ml-3">1 CPU Core(s)</span>
                        </li>
                        <li className="flex items-center mt-3">
                        <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                        <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <span className="text-indigo-200 text-md ml-3">1 IP Addresses</span>
                        </li>
                        <li className="flex items-center mt-3">
                        <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                        <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <span className="text-indigo-200 text-md ml-3">Unlimited Hosted Domains</span>
                        </li>
                        <li className="flex items-center mt-3">
                        <div className="bg-indigo-200 rounded-full p-2 fill-current text-indigo-700">
                        <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <span className="text-indigo-200 text-md ml-3">CentOS/Debian/Ubuntu</span>
                        </li>
                    </ul>
                </div>
                <Link className="block flex items-center justify-center bg-indigo-900 hover:bg-indigo-700 p-4 text-md font-semibold text-gray-300 uppercase mt-4" to="/hosting">
                    <span>Purchase</span>
                    <span className="font-medium text-gray-300 ml-2">➔</span>
                </Link>
                </div>
            </div>
        </div>
        
        
    </div>

    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="mt-10 border-t border-dashed border-indigo-300 pt-14">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                              <img src="https://sheephostingbay.duoservers.com/template2/img/features-07.webp" alt="Characteristics"/>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">Characteristics</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      The Virtual Private Server plans are loaded with all the characteristics and software tools you may need in order to 
                      manage your resource–demanding sites and apps from a single area. Each VPS pack provides a variety of OS’s, SSD 
                      storage space, root and SSH access and regular off–site backups. You’ll have guaranteed CPU and memory 
                      allowances for speedier data transfer rates.
                      </dd>
                  </div>
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <img src="https://sheephostingbay.duoservers.com/template2/img/ssd-02.webp" alt="SSD storage"/>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">SSD storage</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      Each and every of the Virtual Servers offered by Sheephostingbay is designed with SSDs as an alternative to common HDDs. 
                      Getting SSD disks on your VPS may be a good advantage for your sites. SSDs offer much better read/write data transfer 
                      rates and in addition enable faster file access. This essentially indicates that every little thing on your server 
                      will work much faster, including all your websites and applications.
                      </dd>
                  </div>
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <img src="https://sheephostingbay.duoservers.com/template2/img/centos-debian-ubuntu-06.webp" alt="OS choices"/>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">OS choices</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      We supply you with a selection of Linux distributions for your virtual server at signup. On the order form, you could opt 
                      for between the following Linux OS options – CentOS, Debian or Ubuntu. Only go for the one that you need and we’re going 
                      to set it up for you.
                      </dd>
                  </div>
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <img src="https://sheephostingbay.duoservers.com/template2/img/free-bonuses-05.webp" alt="A no cost dedicated IP address"/>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">A no cost dedicated IP address</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      A Virtual Private Server offers you total freedom on the web, and to assist you to begin effectively, we provide you 
                      with a dedicated IP address totally free. When you enrol, all you have to do is let us know which Linux OS version 
                      you desire and we’ll arrange your machine and give a special dedicated IP to you for no cost. In case you need 
                      additional IP addresses, you can buy them from your Control Panel at an inexpensive price.
                      </dd>
                  </div>
              </dl>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default VpsPage
